import React, { useState } from "react";
import axios from 'axios'

import "./AddFitbit.css";

import { Link } from "react-router-dom";
import Config from "../../utils/Config";
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from "react-router-dom";


const AddFitbit = () => {

  const navigate = useNavigate();

  const [program, setProgram] = useState("")
  const [location, setLocation] = useState("")
  const [fullName, setFullName] = useState("")
  
  const token = localStorage.getItem("token");

  const notifyError = (text) => toast.error(text);

  function handleInputProgram(event) {
    setProgram(event.target.value);
  }
  function handleInputLocation(event) {
    setLocation(event.target.value);
  }
  function handleInputName(event) {
    setFullName(event.target.value);
  }

  async function handleSubmit(event) {

    event.preventDefault();

    if((program === "") || (fullName === "") || (location === "")) {
      notifyError("Fields are blank");
      navigate("/add_fitbit")
    }
    else {
      const formData = new FormData();
      formData.append('program', program);
      formData.append('location', location);
      formData.append('fullName', fullName)

      var logout = window.open("https://www.fitbit.com/logout/transferpage", '_blank');
      window.setTimeout(function(){
          logout.close();
        }, 200);
      
      //window.open('https://www.fitbit.com/logout/transferpage', '_blank'); 
      
      try {
        const response = await axios.post(`${Config.API_URL}/get-link`,
          formData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        const link = response.data
        window.setTimeout(function(){
          setProgram("")
          setLocation("")
          setFullName("")
          window.open(link, '_blank');
        }, 500);
        //window.open(link, '_blank');
      
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate("/login");
        } else {
          notifyError(error.response.data.detail);
        }
      }
    }

}
  return (
    <div className="addFitbit">
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <div className="addFitbit_Data">
        <h1>Register a Patient</h1>

        <div className="buttons">

          <input
            className="RegisterFromData"
            name="program"
            value={program}
            required
            onChange={handleInputProgram}
            type="text"
            placeholder="Program"
          />
          <input
            className="RegisterFromData"
            name="location"
            value={location}
            required
            onChange={handleInputLocation}
            type="text"
            placeholder="Location"
          />
          <input
            className="RegisterFromData"
            name="fullName"
            value={fullName}
            required
            onChange={handleInputName}
            type="text"
            placeholder="Patient Full Name"
          />
          <button className="btn1" onClick={handleSubmit}>Register a Fitbit</button>
          {/*<button className="btn2">Other Device</button>*/}
        </div>
        {/*
        <Link>
          <p>Need Help?</p>
        </Link>
        <Link>
          <p>Request a Connection</p>
        </Link>*/}
      </div>
    </div>
  );
};

export default AddFitbit;
