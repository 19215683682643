const Config = {
    API_URL: 'https://activtrackrtm.com/api',
    BASE_URL: 'https://activtrackrtm.com'
    // API_URL: 'http://localhost:3030/api',
    // BASE_URL: 'http://localhost:3030'
}

/*
    API_URL: 'http://localhost:3030/api',
    BASE_URL: 'http://localhost:3030'
    API_URL: 'https://activtrackrtm.com/api',
    BASE_URL: 'https://activtrackrtm.com'
*/
export default Config
