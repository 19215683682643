import React, { useState } from "react";
import "./Forgot.css";
import { useNavigate } from "react-router-dom";
import Logo from "../../image/Logo";
import Footer from "../Footer/Footer";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import Config from "../../utils/Config";
import { useFormik } from "formik";
import { submitSchemaReset} from "../../schemas";

const delay = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);

const initialValues = {
  reset_code: "",
  password: "",
  confirmPassword: "",
};

const Forgot = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [forgotData, setForgotData] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForgotData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axios({
      method: "POST",
      url: `${Config.API_URL}/forgot`,
      data: forgotData,
      JSON: true,
    })
      .then((response) => {
        setLoading(false);
        toast.success("Check your email for reset link");
        //navigate("/");
      })
      .catch((error) => {
        toast.error("Error. Please try again later or email IT help desk " + error)
        setLoading(false);
      });
  };

  const handleResetSubmit = async (values) => {
    setLoading(true);
      const data = {
        email: forgotData.email,
        reset_code: values.reset_code,
        password: values.password,
      };
      await axios({
        method: "POST",
        url: `${Config.API_URL}/reset_pwd`,
        data: data,
        JSON: true,
      })
      .then((response) => {
        toast.success("Password updated successfully.");
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Error. Please try again later or email IT help desk " + error)
        setLoading(false);
      });
  };

  //const { values, errors, handleBlur, handleChange, handleSubmit, touched } =
  const formik = useFormik({
      initialValues: initialValues,
      validationSchema: submitSchemaReset,
      
      onSubmit: async (values) => {
        handleResetSubmit(values)
        delay(1000);
        navigate("/");
      }
    })

  return (
    <div className="page_wrapper">
      <Toaster position="top-center" reverseOrder={false} />

      <div className="forgot">
        <Logo />
        <h1>ActivTrack</h1>
        <h5>Forgot Password</h5>

        <div className="forgotForm">
          <h2>Enter your email to receive email reset link!</h2>
          <form>
            <div>
              <input
                className="loginFromData"
                name="email"
                required
                value={forgotData.email}
                onChange={handleInputChange}
                type="email"
                placeholder="Enter your email/username"
                /> 
            </div>
            <div className="forgotButtonDiv">
              <button type="submit" className="forgotButton" onClick={handleEmailSubmit}>
                {loading ? "Loading..." : "Send Reset Email"}
              </button>
            </div>
          </form>
          <div>
              <br></br>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div>
              <input
                className="loginFromData"
                name="reset_code"
                value={formik.values.reset_code}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                placeholder="Emailed Reset Code"
              />
              {formik.errors.reset_code && formik.touched.reset_code ? (
                <div className="formError">{formik.errors.reset_code}</div>
              ) : null}
            </div>
            <div>
              <input
                className="loginFromData"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="password"
                placeholder="New Password"
              />
              {formik.errors.password && formik.touched.password ? (
                <div className="formError">{formik.errors.password}</div>
              ) : null}
            </div>
            <div>
              <input
                className="loginFromData"
                name="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="password"
                placeholder="Confirm New Password"
              />
              {formik.errors.confirmPassword && formik.touched.confirmPassword ? (
                <div className="formError">{formik.errors.confirmPassword}</div>
              ) : null}
            </div>
            <div><br></br></div>
            <div>
              <h6> Password must abide by the following: </h6> <br></br>
              <ul>
                <li>- At least 1 uppercase Character</li>
                <li>- At least 1 lowercase Character</li>
                <li>- At least 1 digit</li>
              </ul> 
            </div>
            <div className="forgotButtonDiv">
              <button type="submit" className="forgotButton" onSubmit={formik.handleSubmit}>
                {loading ? "Loading..." : "Update Password"}
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Forgot;
