import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./Table.css";
import Navbar from "../Navbar/Navbar";
// import profile from "../../image/profile.png";
// import find from "../../image/find.png";
// import arrow from "../../image/arrow.png";
import Edit from "../../image/edit3.png";
import Save from "../../image/save3.png";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import Config from "../../utils/Config";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

function getCurrentMonthTotalDays() {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const totalDays = new Date(currentYear, currentMonth + 1, 0).getDate();

  return totalDays;
}

function getProgressBarColor(billing) {
  if (billing < 4) {
    return "bg-danger"; // Red color for values less than 4 
  } else if (billing < 8) {
    return "bg-warning"; // Yellow color for values between 4 and 8
  } else {
    return "bg-success"; // Green color for values greater than or equal to 8
  }
}

function  getComplianceBarColor(rate) {
  if (rate < 50) {
    return "bg-danger"; // Red color for values less than 4 
  } else if (rate < 75) {
    return "bg-warning"; // Yellow color for values between 4 and 8
  } else {
    return "bg-success"; // Green color for values greater than or equal to 8
  }
}

const Table = () => {
  //const [patients, setPatients] = useState([]);
  const patients = useRef([]);
  const [currentPage, setCurrentPage] = useState(1);
  // const patientsPerPage = 5;
  const navigate = useNavigate();
  // const totalDaysInCurrentMonth = getCurrentMonthTotalDays();
  const [isLoading, setIsLoading] = useState(true);

  // you can change the rows per page for your table from here 
  const rowsPerPage = 10; 
  const notifyError = (text) => toast.error(text);
  var notified = false;

  const token = localStorage.getItem("token");

  var [edited, setEdited] = useState(0);
  const lastEdited = useRef(-1);

  var indexOfLastPatient = currentPage * rowsPerPage;
  var indexOfFirstPatient = indexOfLastPatient - rowsPerPage;
  //const [currentPatients, setCurrentPatients] = useState([]);
  const currentPatients = useRef([]);

  useEffect(() => {
    setTimeout(function() {
      fetchData();
    }, 20);
  });


  const fetchData = async () => {
    try {
      const response = await axios.post(
        `${Config.API_URL}/data/all_patients`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = JSON.parse(response.data);
      //setPatients(data);
      patients.current = data;
      currentPatients.current = data.slice(indexOfFirstPatient, indexOfLastPatient);
      //setCurrentPatients(data.slice(indexOfFirstPatient, indexOfLastPatient));

      setIsLoading(false);

      //setIsLoading(false);
    } catch (error) {

      if (error.response && error.response.status === 401) {
        navigate("/login");
      }

      if (error.response && error.response.status === 404) {
        patients.current = []
        currentPatients.current = []
        if (notified === false) {
          notifyError("No patients found")
          notified = true;
        }
        
        //navigate("/login");
        setIsLoading(false);
      }
    }
  };

  // Handle page change
  const handlePageChange = (pageNumber) => {
    indexOfLastPatient = (pageNumber) * rowsPerPage;
    indexOfFirstPatient = indexOfLastPatient - rowsPerPage;
    currentPatients.current = patients.current.slice(indexOfFirstPatient, indexOfLastPatient);
    setCurrentPage(pageNumber);
  };

  const name_clicked = (name) => {
    // console.log(name)
    localStorage.setItem('SelectedPatient', name);
    localStorage.setItem('startTime', new Date());
    return true;
  };

  const edit_name = (index,patient) => {

    if((lastEdited.current !== index) && (edited === 1)) {
      return;
    }

    if(edited === 0) {
      const name = document.getElementById("name_field_"+index);
      name.setAttribute("contentEditable", "true");
      
      const test = document.getElementById("name_field_"+index).getElementsByTagName('a')[0];
      name.innerHTML = test.innerText

      try{
        const edit_icon = document.getElementById("edit_icon_"+index);
        edit_icon.setAttribute("src",Save);
      }
      catch {
        console.log("err")
      }

      lastEdited.current = index;

      setEdited(1);
    }

    var old_name = patient.full_name;
    var new_name = "";

    if(edited === 1) {
      //<a href="/dashboard">Karen Fidance</a>sdfsd
      try{
        var name = document.getElementById("name_field_"+index);
        new_name = name.innerHTML;
        new_name = new_name.replace(/\&nbsp;/g, '').trim()

        patient.full_name = new_name

        name.setAttribute("contentEditable","false");
        name.innerHTML = '<a id=name_link_' + index + '>'+new_name+'</a>'

        var name_link = document.getElementById("name_link_"+index);
        name_link.href = "/dashboard"
        name_link.onclick = function() {name_clicked(patient.full_name);}; // for IE

        var edit_icon = document.getElementById("edit_icon_"+index);
        edit_icon.setAttribute("src",Edit);

        lastEdited.current = -1;

        if(new_name !== old_name){
          change_name(patient, old_name, new_name)
        }
      }
      catch (error){
        console.log(error)
      }
      setEdited(0);
    }
  };

  const change_name = async(patient, old_name, new_name) => {

    let formData = new FormData();
    formData.append("patient_id", patient.id);
    formData.append("new_name", new_name);
    formData.append("old_name", old_name);

    // console.log(patient.id, new_name)

    try {
      await axios.post(`${Config.API_URL}/set_new_name`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        toast.success("Changed Name Succesfully")
      }).catch((error) => {
        toast.error(error.response.data.detail.msg);
      });
    } catch (error) {
      toast.error("Error saving name");
      }
  };


  return (
    <div>
    {isLoading ? (<div className="loading">Loading...</div>) : (
      <div className="mainTable">
        <Navbar />
        <div className="container-fluid">
        <Toaster position="top-center" reverseOrder={false} />
          <div className="table_data">
            <div className="pagination_and_search">
              <h4>Your Patients</h4>
            </div>
            <div className="table_area">
              <table>
                <thead>
                  <tr key="header">
                    <th>Index</th>
                    <th>&nbsp;</th>
                    <th>Name</th>
                    <th>Program</th>
                    <th>Location</th>
                    <th>Age</th>
                    <th>Start Date</th>
                    <th>Today's Steps</th>
                  </tr>
                </thead>
                <tbody id="patients_table">
                  {currentPatients.current.map((patient, index) => (
                    <tr key={index}>
                      <td>{indexOfFirstPatient + index + 1}</td>
                      <td><img id={"edit_icon_"+index} src={Edit} style={{width: "40px", height: "40px"}} onClick={() => edit_name(index,patient)}/></td>
                      <td id={"name_field_"+index} contentEditable="false"><a href="/dashboard" id={"name_link_"+index} onClick={() => name_clicked(patient.full_name)}>{patient.full_name}</a></td>
                      <td>{patient.program}</td>
                      <td>{patient.location}</td>
                      <td>{patient.age}</td>
                      <td>{new Date(patient.start_date).toLocaleString().split(',')[0]}</td>
                      <td>{patient.steps}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="paginatin_sec">
              <ul>Page &nbsp;
              {Array(Math.ceil(patients.current.length / rowsPerPage))
                .fill()
                .map((_, index) => (
                  <li key={-1}>
                    <Link className={`page ${currentPage === index + 1 ? "current" : ""}`} 
                    onClick={() => handlePageChange(index + 1)}>{index + 1}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="progress_bar_file">
            <div className="progress_heading">
              <h4>Overall Compliance</h4>
            </div>
            {patients.current.map((patient, index) => (
            <div className="progress_list">
              <div className="gap">
                <div className="tableName">{patient.full_name}</div>
                <div className="tableProgress">
                  <div className="row align-items-center">
                    <div className="col-sm-11">
                      <div className="progress">
                        <div
                          className={`progress-bar ${getComplianceBarColor(patient.compliance_rate)}`}
                          role="progressbar"
                          style={{ width: (patient.compliance_rate) + "%", "borderRadius": "10px"}}
                          aria-valuenow="55"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                    <div className="col-sm-1">
                      <div className="tableText">{(patient.compliance_rate).toFixed(0) >= 100 ? 100 : (patient.compliance_rate).toFixed(0)}%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            ))}
          </div>

          <div className="progress_bar_file">
            <div className="progress_heading">
              <h4>Billing Readiness</h4>
            </div>
            {patients.current.map((patient, index) => (
            <div className="progress_list">
              <div className="gap">
                <div className="tableName">{patient.full_name}</div>
                <div className="tableProgress">
                  <div className="row align-items-center">
                    <div className="col-sm-11">
                      <div className="progress">
                        <div
                          className={`progress-bar ${getProgressBarColor(patient.billing)}`}
                          role="progressbar"
                          style={{ width: ((patient.billing * 100) / 16) + "%", "borderRadius": "10px"}}
                          aria-valuenow="55"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                    <div className="col-sm-1">
                      <div className="tableText">{((patient.billing * 100) / 16).toFixed(0) >= 100 ? 100 : ((patient.billing * 100) / 16).toFixed(0)}%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            ))}
          </div>
        </div>
        <Footer />
      </div>
    )
  } </div> );
};

export default Table;
