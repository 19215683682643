import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./component/Dashboard/Dashboard";
import Table from "./component/Table/Table";
import Profile from "./component/Profile/Profile";
import Login from "./component/Login/Login";
import Register from "./component/Register/Register";
import Forgot from "./component/Forgot/Forgot";
import Layout from "./component/Layout/Layout";
import AddFitbit from "./component/AddFitbit/AddFitbit";
import Authorized from "./component/Authorized/Authorized";
import Exist from "./component/Authorized/Exist";
import LandingPage from "./component/Landing/LandingPage";


const isAuth = () => {
  const userAccount = localStorage.getItem("token");
  const isAuthenticated = userAccount !== null;
  return isAuthenticated;
};

function PrivateRoute({ children }) {
  const auth = isAuth();
  return auth ? <>{children}</> : <Navigate to="/home" />;
}

function App() {
  return (

    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/home" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/*" element={<Navigate to="/" />} />
          <Route path="/" element={<PrivateRoute><Layout /></PrivateRoute>}>
            <Route index element={<Table />} />
            <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path="/authorized" element={<PrivateRoute><Authorized /></PrivateRoute>} />
            <Route path="/exist" element={<PrivateRoute><Exist /></PrivateRoute>} />
            <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
            <Route path="/add_fitbit" element={<PrivateRoute><AddFitbit /></PrivateRoute>} />
            <Route path="/register" element={<PrivateRoute><Register /></PrivateRoute>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
