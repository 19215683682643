import React, { useState } from "react";
import "./Navbar.css";
import notification from "../../image/notifition.png";
import profile from "../../image/profile.png";
import dropDown from "../../image/dropDown.png";
import menuIcon from "../../image/menu-icon.png";
import search from "../../image/search.png";
import { Link } from "react-router-dom";
import SideBar from "../SideBar/SideBar";


const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const handaleToggle = () => {
    setToggle(!toggle);
  };
  return (
    <div className="container-fluid">
      <div className="top_part">
        <div className="mobile_menu" onClick={handaleToggle}>
          <img src={menuIcon} alt="menu icon" />
          <SideBar toggle={toggle} />
        </div>
        {/* <div className="row align-items-center">
          <div className="col-6">
            <div className="search_form">
              <input type="text" placeholder="Search for a patient" />
              <div className="SearchDropdown">
                <div className="dropdown">
                  <button
                    className="dropDown"
                    type="button"
                    data-bs-toggle="dropdown"
                  >
                    <img src={dropDown} />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <Link className="dropdown-item">Action</Link>
                    </li>
                    <li>
                      <Link className="dropdown-item"> Another action</Link>
                    </li>
                    <li>
                      <Link className="dropdown-item">Something else here</Link>
                    </li>
                  </ul>
                </div>
                <button className="SearchButton">
                  <img src={search} />
                </button>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="right_info">
              <ul>
                <li>
                  <Link>
                    <div>
                      {/* <div className="dropdown ">
                        <img
                          data-bs-toggle="dropdown"
                          src={notification}
                          alt="icon"
                        />
                        <span className="number_count">01</span>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <Link className="dropdown-item">Action</Link>
                          </li>
                          <li>
                            <Link className="dropdown-item">
                              Another action
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item">
                              Something else here
                            </Link>
                          </li>
                        </ul>
                      </div> 
                    </div>
                  </Link>
                </li>
                <li>
                  {/* <span>Dr. Deshmukh</span> */}
                  {/* <img src={profile} alt="profile pic" /> 

                </li>
              </ul>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Navbar;
