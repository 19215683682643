import React, { useState } from "react";
import axios from 'axios'

import "./AddFitbit.css";

import { Link } from "react-router-dom";
import Config from "../../utils/Config";
import toast, { Toaster } from 'react-hot-toast';


const Authorized = () => {

  const [program, setProgram] = useState("")
  const [location, setLocation] = useState("")
  const [fullName, setFullName] = useState("")

  const notifyError = (text) => toast.error(text);

  function handleInputProgram(event) {
    setProgram(event.target.value);
  }
  function handleInputLocation(event) {
    setLocation(event.target.value);
  }
  function handleInputName(event) {
    setFullName(event.target.value);
  }
  
  async function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData();
    formData.append('program', program);
    formData.append('location', location);
    formData.append('fullName', fullName)

    try {
      const response = await axios.post(`${Config.API_URL}/get-link`,
        formData);
      const link = response.data
      //window.open(link, '_blank');
      window.setTimeout(function(){
        window.open(link, '_blank');
      }, 5000);
    } catch (error) {
      notifyError("Sorry! Something went wrong")
    }
  }

  var logout = window.open("https://www.fitbit.com/logout/transferpage", '_blank');
    window.setTimeout(function(){
        logout.close();
  }, 200);
  
  return (
    <div className="addFitbit">
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <div className="addFitbit_Data">
        <h1>Register a Patient</h1>
        <h3 style={{ color: 'green' }}>You are successfully authorized</h3> 
        <h5>(You may close this window)</h5>        
        {/* <div className="buttons">

          <input
            className="RegisterFromData"
            name="location"
            value={location}
            required
            onChange={handleInputLocation}
            type="text"
            placeholder="Location"
          />
          <input
            className="RegisterFromData"
            name="program"
            value={program}
            required
            onChange={handleInputProgram}
            type="text"
            placeholder="Program"
          />
          <button className="btn1" onClick={handleSubmit}>Register a Fitbit</button>
          <button className="btn2">Other Device</button>
        </div>

        <Link>
          <p>Need Help ?</p>
        </Link>
        <Link>
          <p>Request a Connection</p>
        </Link> */}
      </div>
    </div>
  );
};

export default Authorized;
