import React, { useState, useEffect } from "react";
import axios from "axios";
import "./SideBar.css";
import Logo from "../../image/Logo";
import closeIcon from "../../image/close.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Config from "../../utils/Config";
import toast from "react-hot-toast";

const SideBar = (props) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  const handleSwitch = async () => {
    if(window.location.href.includes("dashboard")) {
      let startTime = localStorage.getItem("startTime");
      let endTime = new Date();
      localStorage.setItem("endTime", endTime);
      const token = localStorage.getItem("token");

      let timeSpent = endTime - Date.parse(startTime);

      // saveTimeSpent(previousPatientId, timeSpent);
      let hours = Math.floor(timeSpent / (1000 * 60 * 60));
      let minutes = Math.floor((timeSpent % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((timeSpent % (1000 * 60)) / 1000);

      if(minutes > 20) {
        minutes = 20;
      }
      
      if(hours > 0) {
        hours = 0;
      }

      //f("id: ", localStorage.getItem('previousPatientID'))

      let formData = new FormData();
      formData.append("id", localStorage.getItem('previousPatientID'));
      formData.append("hours", parseInt(hours));
      formData.append("mins", parseInt(minutes));
      formData.append("sec", parseInt(seconds));

      try {
        await axios.post(`${Config.API_URL}/set_reviewing_time`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => {
          //console.log(seconds)
          //toast.success("Saved reviewing time") 
        }).catch((error) => {
          toast.error(error.response.data.detail);
        });
      } catch (error) {
        toast.error("Error saving reviewing time");
      }
    }
  };

  const sidebarNavItems = [
    {
      display: "Patient Dashboard",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-border-all"
          viewBox="0 0 16 16"
        >
          <path d="M0 0h16v16H0V0zm1 1v6.5h6.5V1H1zm7.5 0v6.5H15V1H8.5zM15 8.5H8.5V15H15V8.5zM7.5 15V8.5H1V15h6.5z" />
        </svg>
      ),
      to: "/dashboard",
      section: "",
    },
    {
      display: "All Patients",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-table"
          viewBox="0 0 16 16"
        >
          <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z" />
        </svg>
      ),
      onClick: handleSwitch,
      to: "/table",
      section: "started",
    },
    {
      display: "Add Fitbit",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-file-earmark-plus"
          viewBox="0 0 16 16"
        >
          <path d="M8 6.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 .5-.5z" />
          <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z" />
        </svg>
      ),
      onClick: handleSwitch,
      to: "/add_fitbit",
      section: "fitbit",
    },
    
    {
      display: "Profile",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          className="bi bi-person"
          viewBox="0 0 16 16"
        >
          <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
        </svg>
      ),
      to: "/profile",
      onClick: handleSwitch,
      section: "calendar",
    },
    {
      display: "Logout",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          className="bi bi-box-arrow-in-right"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"
          />
          <path
            fillRule="evenodd"
            d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
          />
        </svg>
      ),
      onClick: handleLogout,
      to: "/login",
      section: "user",
    },
    // {
    //   display: "Register",
    //   icon: (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       width="18"
    //       height="18"
    //       fill="currentColor"
    //       className="bi bi-pencil-square"
    //       viewBox="0 0 16 16"
    //     >
    //       <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
    //       <path
    //         fillRule="evenodd"
    //         d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
    //       />
    //     </svg>
    //   ),
    //   to: "/Register",
    //   section: "order",
    // },
   
  ];

  return (
    <div className={`sidebar ${props.toggle ? "active_nav" : ""}`}>
      <div className="closeBtn">
        <img src={closeIcon} alt="close" />
      </div>
      <div className="sidebar__logo">
        <a href="/home"><Logo /></a>
      </div>
      {sidebarNavItems.map((item, index) => (
        <Link to={item.to} key={index}>
          <div className="sidebar__menu__item" onClick={item.onClick}>
            <div className="sidebar__menu__item__icon">{item.icon}</div>
            <div className="sidebar__menu__item__text">{item.display}</div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default SideBar;
