import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
  <div>
  <div className="footer">ActivTrack LLC © Copyright 2023 | All rights reserved. <br></br>
  <a href="https://www.activtrackrtm.com/terms.html" target="_blank">Terms of Use & Privacy Policy</a></div>
  </div>);
};

export default Footer;
