import React, { useState } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import Logo from "../../image/Logo";
import Footer from "../Footer/Footer";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import Config from "../../utils/Config";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginData((prevState) => ({ ...prevState, [name]: value }));
  };

  const notify = (text) => toast.error(text);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axios({
      method: "POST",
      url: `${Config.API_URL}/login`,
      data: loginData,
      JSON: true,
    })
      .then((response) => {
        localStorage.setItem("token", response.data.access_token);
        setLoading(false);
        navigate("/table");
      })
      .catch((error) => {
        setLoading(false);
        notify(error.response.data.detail);
      });
  };

  return (
    <div className="page_wrapper">
      <Toaster position="top-center" reverseOrder={false} />

      <div className="login">
        <a href="/home"><Logo /></a>
        <h1>ActivTrack</h1>
        <h5>Login to view your patient dashboard</h5>

        <div className="loginForm">
          <h2>Welcome back!</h2>
          <form onSubmit={handleSubmit}>
            <div>
              <input
                className="loginFromData"
                name="email"
                required
                value={loginData.email}
                onChange={handleInputChange}
                type="email"
                placeholder="Email"
              />
            </div>
            <div>
              <input
                className="loginFromData"
                name="password"
                value={loginData.password}
                onChange={handleInputChange}
                required
                type="password"
                placeholder="Password"
              />
            </div>
            <div className="loginButtonDiv">
              <button type="submit" className="loginButton">
                {loading ? "Loading..." : "Log in"}
              </button>
            </div>
          </form>
          <div
            className="loginAccount"
            onClick={() => {
              navigate("/forgot");
            }}>
            <br></br>Forgot Password?
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
