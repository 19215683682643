import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import FacebookIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/X';

import styled from 'styled-components';

const logoStyle = {
  width: '35px',
  height: 'auto',
};

const StyledLink = styled(Link)`
    text-decoration: none;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {'Copyright © '}
      <Link sx={{ color: '#0087AC'}} href="/home">ActivTrack LLC&nbsp;</Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            minWidth: { xs: '100%', sm: '60%' },
          }}
        >
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            Product
          </Typography>
          <a><Link sx={{ color: '#0087AC'}} href="#">
            Features
          </Link></a>
          <a><Link sx={{ color: '#0087AC'}}  href="#">
            Testimonials
          </Link></a>
          <a><Link sx={{ color: '#0087AC'}}  href="#">
            Highlights
          </Link></a>
          <a><Link sx={{ color: '#0087AC'}}  href="#">
            Pricing
          </Link></a>
          <a><Link sx={{ color: '#0087AC'}}  href="#">
            FAQs
          </Link></a>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            Legal
          </Typography>
          <a><Link sx={{ color: '#0087AC'}} href="/terms.html" target="_blank">
            Terms and Privacy Policy
          </Link></a>
          <a><Link sx={{ color: '#0087AC'}} _hover={{ textDecoration: "none" }} href="mailto:customer-service@activtrackrtm.com" target="_blank">
            Contact
          </Link></a>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 4, sm: 8 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <div>
          <Link color="text.secondary" href="/terms.html">
            Privacy Policy / Terms of Service
          </Link>
          <Copyright />
        </div>
      </Box>
    </Container>
  );
}
