import * as Yup from "yup";

export const submitSchema = Yup.object({
  firstName: Yup.string()
    .min(2, "First name should have at least 2 characters")
    .max(25, "First name should have at most 25 characters")
    .required("Please enter the first name"),
  lastName: Yup.string()
    .min(2, "Last name should have at least 2 characters")
    .max(25, "Last name should have at most 25 characters")
    .required("Please enter the last name"),
  email: Yup.string()
    .email("Invalid email address")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email address"
    )
    .required("Please enter the email"),
  password: Yup.string().min(6).required("Please enter your password")
    .matches(/[0-9]/, "Your password must have at least 1 digit")
    .matches(/[a-z]/, "Your password must have at least 1 lowercase character")
    .matches(/[A-Z]/, "Your password must have at least 1 uppercase character"),
  confirmPassword: Yup.string()
    .required("Please enter your password again")
    .oneOf([Yup.ref("password"), null], "Passwords does not match"),
  policy: Yup.boolean().oneOf([true], "Please check Agree to terms & policy"),
});

export const submitSchemaReset = Yup.object({
  reset_code: Yup.string()
    .required("Please enter the reset code from email"),
  password: Yup.string().min(6).required("Please enter your password")
    .matches(/[0-9]/, "Your password must have at least 1 digit")
    .matches(/[a-z]/, "Your password must have at least 1 lowercase character")
    .matches(/[A-Z]/, "Your password must have at least 1 uppercase character"),
  confirmPassword: Yup.string()
    .required("Please enter your password again")
    .oneOf([Yup.ref("password"), null], "Passwords does not match"),
  policy: Yup.boolean().oneOf([true], "Please check Agree to terms & policy"),
});