import React from "react";
import "./Register.css";
import { useNavigate } from "react-router-dom";
import Logo from "../../image/Logo";
import { useFormik } from "formik";
import { submitSchema } from "../../schemas";
import Footer from "../Footer/Footer";
import axios from 'axios'
import Config from "../../utils/Config";
import toast, { Toaster } from 'react-hot-toast';

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  policy: "",
  report_email: "",
};

const Register = () => {

  const navigate = useNavigate();
  const notifyError = (text) => toast.error(text);
  const notifySuccess = (text) => toast.success(text);

  const { values, errors, handleBlur, handleChange, handleSubmit, touched } =
    useFormik({
      initialValues: initialValues,
      validationSchema: submitSchema,
      
      onSubmit: (values) => {
        const data = {
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          password: values.password,
          report_email: values.report_email,
        };
        axios({
          method: 'POST',
          url: `${Config.API_URL}/register`,
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
            notifySuccess(res.data.message)
            
            navigate("/login")
        })
        .catch(function (res) {
          notifyError(res.response.data.detail)
        });
      }
    })
  const handleLogin = () => {
    navigate("/login");
  };

  return (
    <div className="page_wrapper">
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <div className="login">
        <a href="/home"><Logo /></a>
        <h1>ActivTrack</h1>
        <h5>Sign Up to view your patient dashboard</h5>
        <div className="loginForm registerForm">
          <h2 className="signUpText">Sign Up</h2>
          <form onSubmit={handleSubmit}>
            <div className="RegisterName">
              <div className="dataRegister">
                <input
                  className="RegisterFromData"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  placeholder="First Name"
                />
                {errors.firstName && touched.firstName ? (
                  <div className="formError">{errors.firstName}</div>
                ) : null}
              </div>
              <div className="dataRegister">
                <input
                  className="RegisterFromData"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  placeholder="Last Name"
                />
                {errors.lastName && touched.lastName ? (
                  <div className="formError">{errors.lastName}</div>
                ) : null}
              </div>
            </div>
            <div>
              <input
                className="loginFromData"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                type="email"
                placeholder="Email"
              />
              {errors.email && touched.email ? (
                <div className="formError">{errors.email}</div>
              ) : null}
            </div>
            <div>
              <input
                className="loginFromData"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                type="password"
                placeholder="Password"
              />
              {errors.password && touched.password ? (
                <div className="formError">{errors.password}</div>
              ) : null}
            </div>
            <div>
              <input
                className="loginFromData"
                name="confirmPassword"
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                type="password"
                placeholder="Confirm Password"
              />
              {errors.confirmPassword && touched.confirmPassword ? (
                <div className="formError">{errors.confirmPassword}</div>
              ) : null}
            </div>
            <div>
              <input
                className="loginFromData"
                name="report_email"
                value={values.report_email}
                onChange={handleChange}
                onBlur={handleBlur}
                type="email"
                placeholder="Billing Dept Email"
              />
              {errors.email && touched.email ? (
                <div className="formError">{errors.email}</div>
              ) : null}
            </div>
            <div className="form-check mt-4">
              <input
                className="form-check-input"
                name="policy"
                value={values.policy}
                onChange={handleChange}
                onBlur={handleBlur}
                type="checkbox"
              />
              <label
                className="form-check-label registerCondition"
                htmlFor="disabledFieldsetCheck">
                I accept the <a href="https://www.activtrackrtm.com/terms.html" target="_blank" rel="noopener noreferrer">Terms of Use & Privacy Policy</a>
              </label>
              {<div className="formError">{errors.policy}</div>}
            </div><br></br>
            <div>
              <h6> Password must abide by the following: </h6> <br></br>
              <ul>
                <li>- At least 1 uppercase Character</li>
                <li>- At least 1 lowercase Character</li>
                <li>- At least 1 digit</li>
              </ul> 
            </div>
            <div className="loginButtonDiv">
              <button type="submit" className="loginButton">
                Sign Up
              </button>
            </div>
          </form>
                
          <div className="RegisterAccount">
            Already have an account?
            <div className="RegisterSignIn" onClick={handleLogin}>
              Sign In
            </div>{" "}
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Register;
