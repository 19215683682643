import React, { useRef, useState, useEffect } from "react";
import axios from "axios";

import "react-datepicker/dist/react-datepicker.css";
import "./Dashboard.css";
import bill from "../../image/bill.png";
import time from "../../image/time.png";
import rate from "../../image/rate.png";
import activity from "../../image/activity.png";
import menuIcon from "../../image/menu-icon.png";
import SideBar from "../SideBar/SideBar";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import zoomPlugin from 'chartjs-plugin-zoom';
import DatePicker from "react-datepicker";

//import { useBeforeUnload } from "react-router-dom"

import {
  Chart as ChartJS,
  CategoryScale,
  LineElement,
  PointElement,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineController,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Footer from "../Footer/Footer";

import Config from "../../utils/Config";

import { Portal } from "react-overlays";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  LineController,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

function getCurrentMonthTotalDays() {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const totalDays = new Date(currentYear, currentMonth + 1, 0).getDate();

  return totalDays;
}

async function setPageStartTime() {
  localStorage.setItem('startTime', new Date());
  return "done";
}

const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

const Dashboard = () => {
  const totalDaysInCurrentMonth = getCurrentMonthTotalDays();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [patients, setPatients] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [previousPatientId, setPreviousPatientId] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState([]);
  const [startTime, setStartTime] = useState(new Date());
  const [percentOfTwentyMinutes, setPercentOfTwentyMinutes] = useState(0);
  const [percentOfSteps, setPercentOfSteps] = useState(0);
  const [user, setUser] = useState({});
  const [checkedCustomReport, setCheckedCustomReport] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasWeight, setHasWeight] = useState(false);
  const [hasHr, setHasHr] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const pageClickRef = useRef();

  localStorage.setItem('leftPage', "0");
  
  const notifyError = (text) => toast.error(text);

  const CalendarContainer = ({ children }) => {
  const el = document.getElementById("calendar-portal");
    return <Portal container={el}>{children}</Portal>;
  }; //asdf

  const [stepChartData, setStepChartData] = useState({
    type: "bar",
    labels: labels,
    datasets: [
      {
        type: "line",
        label: "Prediction",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        backgroundColor: "red",
        borderColor: "red",
        pointRadius: 3,
      },
      {
        type: "bar",
        label: "Steps",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        backgroundColor: "rgba(0,135,172,1.000)",
        borderColor: "rgba(0,135,172,1.000)",
        borderWidth: 1,
        borderRadius: 3,
        categoryPercentage: 1.0,
        barPercentage: 0.6,
      },
    ],
  });

  const [hrChartData, setHrChartData] = useState({
    type: "bar",
    labels: labels,
    datasets: [
      {
        type: "bar",
        label: "Max HR",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        backgroundColor: "rgba(0,135,172,1.000)",
        borderColor: "rgba(0,135,172,1.000)",
        borderWidth: 1,
        borderRadius: 3,
        categoryPercentage: 1.0,
        barPercentage: 0.6,
      },
    ],
  });

  const [weightChartData, setWeightChartData] = useState({
    type: "bar",
    labels: labels,
    datasets: [
      {
        type: "bar",
        label: "Weight (kg)",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        backgroundColor: "rgba(0,135,172,1.000)",
        borderColor: "rgba(0,135,172,1.000)",
        borderWidth: 1,
        borderRadius: 3,
        categoryPercentage: 1.0,
        barPercentage: 0.6,
      },
    ],
  });

  // setTimeout(()=>{createExtraGraphs(selectedPatient, user)}, 3000);

  useEffect(() => {
    //console.log("user defined: ", user, "patient defined: ", selectedPatient)

    if(typeof user.features !== 'undefined' && typeof selectedPatient.full_name !== 'undefined'){
      createExtraGraphs(selectedPatient, user)
    }
    else {
      //console.log("waiting")
    }
     
   }, [user, selectedPatient]);

  useEffect(() => {
    setPageStartTime()
    localStorage.setItem('leftPage', "0");

    window.onclick = (event) => {
      if (event.target.contains(pageClickRef.current)
        && event.target !== pageClickRef.current) {
      } else {
        localStorage.setItem('leftPage', "0");
      }
    }

    const fetchData = async () => {
      //setIsLoading(true);

      try { //Need to create new endpoint for getting a specific patient's data. one end point to return a list of patient names (loaded on page load)
        const response = await axios.post( //another endpoint should then be used to query a specific patient's data
          `${Config.API_URL}/data/all_patients`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = JSON.parse(response.data);
 
        setIsLoading(false);
        if (data !== null) {
          setPatients(data);
          //console.log(data)

          for (let i = 0; i < data.length; i++) {
            if(data[i]["full_name"] === localStorage.getItem("SelectedPatient")) {

              handleSelectPatient(data[i], user);

              break;
            }
          }
        }

      } catch (error) {
        // setIsLoading(false)
        if (error.response && error.response.status === 401) {
          navigate("/login");
        } 
      }

      setIsLoading(false)
    };

    const fetchUser = async () => {
      //setIsLoading(true);
      try {
        const response = await axios.get(`${Config.API_URL}/profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setUser(response.data);

        // function waitForElement(){
        //   if((typeof user.features !== "undefined") && (selectedPatient !== [])){
        //       createExtraGraphs(selectedPatient, user)
        //   }
        //   else{
        //       console.log(user, selectedPatient)
        //       setTimeout(waitForElement, 250);
        //   }
        // } //wait

        // waitForElement()

      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate("/login");
        } else {
          notifyError(error.response.data.detail);
        }
      }
      // setIsLoading(false);
    };

    fetchUser();
    fetchData(); //call this function when handleSearchInputChange is callend. Change to only query database for selected patient
    // setTimeout(()=>{createExtraGraphs(selectedPatient, user)}, 3000);

    const mouseHandler = (event) => {
      localStorage.setItem('leftPage', "0");
    };

    if(window.location.href.includes("dashboard")) {
      window.addEventListener("beforeunload", (event) => {
          handleSwitch();
      });
      window.addEventListener("pagehide", (event) => {
          handleSwitch();
      });
      document.addEventListener("visibilitychange", (event) => {
          handleSwitch();
      });
   
      window.addEventListener("unload", (event) => {
          handleSwitch();
      });

      document.addEventListener("mousedown", mouseHandler);
    }

    if(!window.location.href.includes("dashboard")) {
      
      window.removeEventListener("beforeunload", (event) => {
          handleSwitch();
      });
      window.removeEventListener("pagehide", (event) => {
          handleSwitch();
      });

      document.removeEventListener("visibilitychange", (event) => {
          handleSwitch();
      });
      
      window.removeEventListener("unload", (event) => {
          handleSwitch();
      }); 

      document.removeEventListener("mousedown", mouseHandler);
    }

    return () => {

      window.removeEventListener("beforeunload", (event) => {
          handleSwitch();
      });

      window.removeEventListener("pagehide", (event) => {
          handleSwitch();
      });

      document.removeEventListener("visibilitychange", (event) => {
          handleSwitch();
      });
      
      window.removeEventListener("unload", (event) => {
          handleSwitch();
      });

      document.removeEventListener("mousedown", mouseHandler);
    } 

  }, [token, navigate]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSwitch = async () => {

    let leftPage = "-1"
    
    leftPage = localStorage.getItem('leftPage')

    if((window.location.href.includes("dashboard")) && (leftPage === "0")) {
      localStorage.setItem('leftPage', 1);
      let startTime = localStorage.getItem("startTime");
      let endTime = new Date();
      localStorage.setItem("endTime", endTime);
      const token = localStorage.getItem("token");

      let timeSpent = endTime - Date.parse(startTime);

      let hours = Math.floor(timeSpent / (1000 * 60 * 60));
      let minutes = Math.floor((timeSpent % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((timeSpent % (1000 * 60)) / 1000);

      if(minutes > 20) {
        minutes = 20;
      }

      if(hours > 0) {
        hours = 0;
      }

      let formData = new FormData();
      formData.append("id", localStorage.getItem('previousPatientID'));
      formData.append("hours", parseInt(hours));
      formData.append("mins", parseInt(minutes));
      formData.append("sec", parseInt(seconds));

      try {
        await axios.post(`${Config.API_URL}/set_reviewing_time`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => {
          //console.log(timeSpent)
          //toast.success("Saved reviewing time") 
        }).catch((error) => {
          toast.error(error.response.data.detail);
        });
      } catch (error) {
        toast.error("Error saving reviewing time");
      }

      setPageStartTime()
    }
  };

  const handleSearchInputChange = (e) => {
    const query = e.target.value;
    //console.log("handle input change")
    setSearchQuery(query);
    filterPatients(query);
    setShowDropdown(query !== "");
  };

  const filterPatients = (query) => {
    //console.log("filter pts")
    const filtered = patients.filter((patient) =>
      patient.full_name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredPatients(filtered);
  };

  const listAllPatients = async () => {

    try { //Need to create new endpoint for getting a specific patient's data. one end point to return a list of patient names (loaded on page load)
      const response = await axios.post( //another endpoint should then be used to query a specific patient's data
        `${Config.API_URL}/data/list_patients`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      let data = JSON.parse(response.data);

      //console.log(data)

    } catch (error) {
      notifyError(error.response.data.detail);
      //console.log(error.response)
    }

  };

  const handleRemovePatient = async (patientId) => {
    let agree = false

    setIsRemoving(true);
    const response = window.confirm("Are you sure you want to remove that patient?");
    if (response) {
        agree = true
    } else {
        toast.error("Cancelled");
    }

    if(agree) {
      let formData = new FormData();
      formData.append("id", patientId);

      await axios({
      method: "POST",
      url: `${Config.API_URL}/remove_patient`,
      data: JSON.stringify({"value": [patientId]}),
      headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
      })
      .then((response) => {
        
        toast.success("Successfully removed patient");
        navigate("/table");

      })
      .catch((error) => {
        notifyError(error.response.data.detail);
      });
      setPreviousPatientId("")
      localStorage.setItem('previousPatientID', "");
    }
    setIsRemoving(false);
  };

  const handleBillingReport = async (patientId) => {
    setIsGenerating(true);
    //var value = {"fitbit_id": patientId}
    //console.log(patientId)
    //console.log(JSON.stringify({"value": patientId}))
    // console.log([patientId, token, checkedCustomReport, startDate, endDate])
    await axios({
      method: "POST",
      url: `${Config.API_URL}/generate_report`,
      data: JSON.stringify({"value": [patientId, token, checkedCustomReport, startDate, endDate]}),
      headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
    })
      .then((response) => {
        
        toast.success("Check your email for the report!")
      })
      .catch((error) => {
        notifyError(error.response.data.detail);
      });

    setIsGenerating(false);
  };

  const createExtraGraphs = async(patient, user) => {

    // console.log(patient, user)

    if(typeof user.features === 'undefined' || selectedPatient === []){
      return
    }

    // function waitForElement(){
    //     if((typeof user.features !== "undefined") && (selectedPatient !== [])){
    //         createExtraGraphs(selectedPatient, user)
    //     }
    //     else{
    //         console.log("waiting")
    //         setTimeout(waitForElement, 250);
    //     }
    //   } //wait

    //   waitForElement()

    // console.log(patient, user)

    if((patient.month_steps[3].length > 3) && (user.features.includes('heart_rate'))) {
      setHasHr(true);
    }
    if((patient.month_steps[5].length > 3) && (user.features.includes('weight'))) {
      setHasWeight(true);
    }
    
    //handleSwitch()
    // console.log("here2")

    if(hasHr) {
      if((patient.month_steps.length >= 4) && (patient.month_steps[4].length > 3)){
        setHrChartData((prevState) => ({
          ...prevState, // spread out existing state
          labels: patient.month_steps[4],
          datasets: prevState.datasets.map((dataset, i) => {
            if (i === 0) {
              // for bar data
              return {
                ...dataset,
                data: patient.month_steps[3],
              };
            } else if (i === 1) {
              return {
                ...dataset,
                data: [],
              };
            } else {
              return dataset;
            }
          }),
        }));
      }
      else{
        setHrChartData((prevState) => ({
          ...prevState, // spread out existing state
          labels: [],
          datasets: prevState.datasets.map((dataset, i) => {
            if (i === 0) {
              // for bar data
              return {
                ...dataset,
                data: [],
              };
            } else if (i === 1) {
              return {
                ...dataset,
                data: [],
              };
            } else {
              return dataset;
            }
          }),
        }));
      }
    }

    if(hasWeight) {
      if((patient.month_steps.length >= 6) && (patient.month_steps[6].length > 3)){
        setWeightChartData((prevState) => ({
          ...prevState, // spread out existing state
          labels: patient.month_steps[6],
          datasets: prevState.datasets.map((dataset, i) => {
            if (i === 0) {
              // for bar data
              return {
                ...dataset,
                data: patient.month_steps[5],
              };
            } else if (i === 1) {
              return {
                ...dataset,
                data: [],
              };
            } else {
              return dataset;
            }
          }),
        }));
      }
      else{
        setWeightChartData((prevState) => ({
          ...prevState, // spread out existing state
          labels: [],
          datasets: prevState.datasets.map((dataset, i) => {
            if (i === 0) {
              // for bar data
              return {
                ...dataset,
                data: [],
              };
            } else if (i === 1) {
              return {
                ...dataset,
                data: [],
              };
            } else {
              return dataset;
            }
          }),
        }));
      }
    }
  };

  const handleSelectPatient = (patient, user) => {
    setSearchQuery(patient.full_name);
    setSelectedPatient(patient);
    let totalSeconds = patient.reviewing_time.hours * 3600 + patient.reviewing_time.minutes * 60 + patient.reviewing_time.seconds;
    setPercentOfTwentyMinutes((totalSeconds / 1200) * 100);
    setPercentOfSteps((patient.steps / 5000) * 100);
    setStartTime(new Date());
    localStorage.setItem('startTime', startTime);
    localStorage.setItem('SelectedPatient', patient.full_name);

    if(patient.month_steps.length >= 3){
      setStepChartData((prevState) => ({
        ...prevState, // spread out existing state
        labels: patient.month_steps[1],
        datasets: prevState.datasets.map((dataset, i) => {
          if (i === 0) {
            // for bar data
            return {
              ...dataset,
              data: patient.month_steps[2],
            };
          } else if (i === 1) {
            return {
              ...dataset,
              data: patient.month_steps[0],
            };
          } else {
            return dataset;
          }
        }),
      }));
    }
    else{
      setStepChartData((prevState) => ({
        ...prevState, // spread out existing state
        labels: [],
        datasets: prevState.datasets.map((dataset, i) => {
          if (i === 0) {
            // for bar data
            return {
              ...dataset,
              data: [],
            };
          } else if (i === 1) {
            return {
              ...dataset,
              data: [],
            };
          } else {
            return dataset;
          }
        }),
      }));
    }

    setFilteredPatients([]);
    setPreviousPatientId(patient.id);
    setSearchQuery("");
    setShowDropdown(false)
    localStorage.setItem('previousPatientID', patient.id);

    if(typeof user.features !== 'undefined') {
      createExtraGraphs(patient, user)
    }
    else{
      // console.log("not ready")
    }
  };

  //const dataPoint = [0, 50000, 100000, 150000, 200000, 250000, 300000, 350000];
  // const formattedDataPoint = dataPoint.map((num) =>
  //   num.toLocaleString("en-US", {
  //     maximumFractionDigits: 1,
  //     notation: "compact",
  //     compactDisplay: "short",
  //   })
  // );

  const options = {
    indexAxis: "x",
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
    /*zoom: {
      pan: {
          enabled: true,
          mode: 'x'
      },
      zoom: {
          pinch: {
              enabled: true       // Enable pinch zooming
          },
          wheel: {
              enabled: true       // Enable wheel zooming
          },
          mode: 'x',
      }
    },*/
    scales: {
      /*xAxes: [{   
        ticks: {
          padding: 20
        },
        /*max: 50,
        scrollbar: {
          enabled: false
        },
      }],*/
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        min: 0,
        ticks: {
                fontSize: 40
            }
        /*max: 350000,
        ticks: {
          beginAtZero: true,
          stepSize: 50000,
          callback: function (value, index, values) {
            if (index) {
              return formattedDataPoint[index];
            } else {
              return `${formattedDataPoint[index]}`;
            }
          },
        },*/
      },
    },
    tension: 0.1,
  };

  const [toggle, setToggle] = useState(false);
  const handleToggle = () => {
    setToggle(!toggle);
  };

  return (
    <div>
    <div>
      <div ref={pageClickRef} className="container-fluid">
        <Toaster position="top-center" reverseOrder={false} />

        {isLoading ? (
          <div className="loading">Loading...</div>
        ) : patients.length > 0 ? (
          <div>
            <div className="top_part">
              <div className="mobile_menu" onClick={handleToggle}>
                <img src={menuIcon} alt="menu icon" />
                <SideBar toggle={toggle} />
              </div>
              <div className="practice_name">
                <div className="right_info">
                  <div><b>{user.practice_name}</b></div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-6">
                  <div className="search_form">
                    <input
                      type="text"
                      placeholder="Search for a patient..."
                      value={searchQuery}
                      onChange={handleSearchInputChange}
                    />
                    {showDropdown && filteredPatients.length > 0 && (
                      <ul className="dropdown-menu1">
                        {filteredPatients.map((patient) => (
                          <li
                            key={patient.id}
                            onClick={() => handleSelectPatient(patient, user)}
                            className="dropdown-item"
                          >
                            {patient.full_name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="DashboardHead">
              <div>
                <h2>{selectedPatient.full_name}</h2>
              </div>
              <div style={{"display":"flex"}}>
                <button className="Dashboard_button" type="submit" onClick={() => 
                handleRemovePatient(previousPatientId)}>{isRemoving? <>Removing...</> : <>Remove Patient</>}</button>
                <button className="Dashboard_button" type="submit" onClick={() => 
                handleBillingReport(previousPatientId)}>{isGenerating ? <>Generating...</> : <>Generate Report</>}</button>
              </div>
              <div className="customReport">
                <div className="row">
                  <div className="col-md-6 col-lg-3">
                    <div className="row">
                      <div className="col-md-6 col-lg-3">
                        <div className="check_box_custom">
                          <input style={{width: 16, height: 16}} type="checkbox" id="customReport" name="customReport" checked={checkedCustomReport} onChange={() => setCheckedCustomReport(!checkedCustomReport)}/>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3" style={{marginLeft:"10px"}}>
                        <label>Custom Report</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3" style={{marginLeft:"20px"}}>
                    &nbsp; Start:
                    <DatePicker wrapperClassName="datePicker" popperContainer={CalendarContainer} selected={startDate} placeholderText='Start' onChange={(date) => setStartDate(date)}/>
                  </div>
                  <div className="col-md-6 col-lg-3" style={{marginLeft:"20px"}}>
                    &nbsp; End:
                    <DatePicker wrapperClassName="datePicker" popperContainer={CalendarContainer} selected={endDate} placeholderText='End' onChange={(date) => setEndDate(date)}/>
                  </div>
                </div>
              </div>              
            </div>
            <div className="calculated_value">
              <div className="row">
                <div className="col-md-6 col-lg-3">
                  <div className="time">
                    <p>Time spent reviewing</p>
                    <div className="timeData">
                      <div>
                        <img src={time} alt="Time" />
                      </div>
                      {selectedPatient.reviewing_time && (
                        <h6>
                          {selectedPatient.reviewing_time.hours > 9 ? selectedPatient.reviewing_time.hours : "0"+selectedPatient.reviewing_time.hours}:
                          {selectedPatient.reviewing_time.minutes > 9 ? selectedPatient.reviewing_time.minutes : "0"+selectedPatient.reviewing_time.minutes}
                        </h6>
                      )}
                    </div>
                    <div className="progress">
                      <div
                        className="progress-bar bg-success"
                        role="progressbar"
                        style={{ width: `${percentOfTwentyMinutes}%`, "borderRadius": "10px"}}
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="1200"
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="time">
                    <p>Today's Activity</p>
                    <div className="timeData">
                      <div>
                        <img src={activity} alt="activity" />
                      </div>
                      <h6>{selectedPatient.steps} Steps</h6>
                    </div>
                    <div className="progress">
                      <div
                        className="progress-bar bg-warning"
                        role="progressbar"
                        style={{ width: `${percentOfSteps}%`, "borderRadius": "10px"}}
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="5000"
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="time">
                    <p>Compliance rate</p>
                    <div className="timeData">
                      <div>
                        <img src={rate} alt="rate" />
                      </div>
                      <h6>{selectedPatient.compliance_rate} %</h6>
                    </div>
                    <div className="progress">
                      <div
                        className="progress-bar bg-primary"
                        role="progressbar"
                        style={{ width: selectedPatient.compliance_rate + "%", "borderRadius": "10px"}}
                        aria-valuenow="55"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="time">
                    <p>Billing</p>
                    <div className="timeData">
                      <div>
                        <img src={bill} alt="Bill" />
                      </div>
                      <h6>
                        {selectedPatient.billing > 0 ? selectedPatient.billing : "0"}/{totalDaysInCurrentMonth} days
                      </h6>
                    </div>
                    <div className="progress">
                      <div
                        className="progress-bar bg-info"
                        role="progressbar"
                        style={{ width: selectedPatient.billing + "%", "borderRadius": "10px"}}
                        aria-valuenow="55"
                        aria-valuemin="0"
                        aria-valuemax={totalDaysInCurrentMonth}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* STEP CHART PART */}
            <div className="graph">
              <h3>Step Data Overview</h3> <br></br>
              <div style={{width: "100%", "overflowX": "auto"}}>
                <div style={{width: "1500px", height: "500px"}}>
                  <Bar data={stepChartData} height="400" width="0" options={options}></Bar>
                </div>
              </div>
            </div>
            <div>
              <br></br>
            </div>
            {/* WEIGHT CHART PART */}
            {!hasWeight ? (<div></div>) : (
            <div className="graph">
              <h3>Weight Overview</h3> <br></br>
              <div style={{width: "100%", "overflowX": "auto"}}>
                <div style={{width: "1500px", height: "500px"}}>
                  <Bar data={weightChartData} height="400" width="0" options={options}></Bar>
                </div>
              </div>
            </div> )}
            <div>
              <br></br>
            </div>
            {/* HR CHART PART */}
            {!hasHr ? (<div></div>) : (
            <div className="graph">
              <h3>Heart Rate Overview</h3> <br></br>
              <div style={{width: "100%", "overflowX": "auto"}}>
                <div style={{width: "1500px", height: "500px"}}>
                  <Bar data={hrChartData} height="400" width="0" options={options}></Bar>
                </div>
              </div>
            </div> )}

          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>

    <div>
      <div className="container-fluid">
        <Toaster position="top-center" reverseOrder={false} />

        {isLoading ? (
          <div></div>
        ) : patients.length > 0 ? (
          <div>
            <div className="DashboardHead">
            </div>
            <Footer />
          </div>
        ) : (
          <div style={{ paddingTop: "10px" }}>
            <h2>
              Start tracking by{" "}
              {/* <a href={Config.API_URL + "/add_fitbit"}>adding a Fitbit Patient</a> */}
              <Link to={"/add_fitbit"}>adding a Fitbit Patient</Link>
            </h2>
          </div>
        )}
      </div>
    </div>
    </div>
  );
};

export default Dashboard;
