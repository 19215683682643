import React, { useState, useRef, useEffect } from "react";
import "./Profile.css";
import Navbar from "../Navbar/Navbar";
import defaultProfilePicture from "../../image/profile.png";
import Footer from "../Footer/Footer";
import axios from "axios";
import Config from "../../utils/Config";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);

  const notifyError = (text) => toast.error(text);
  const notifySuccess = (text) => toast.success(text);

  const [contact, setContact] = useState({
    address: "",
    report_email: "",
    practice_name: "",
  });

  const [settings, setSettings] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });

  const fileInput = useRef(null);
  const [photo, setPhoto] = useState();
  const [isPhotoChanged, setIsPhotoChanged] = useState(false);

  const fileSelectorHandler = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();

      reader.onload = (e) => {
        setPhoto(e.target.result);
        setIsPhotoChanged(true);
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("file", fileInput.current.files[0]);

    await axios
      .post(`${Config.API_URL}/settings/profile`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        notifySuccess("Profile picture is updated");
        // setPhoto(fileInput.current.files[0]);
      });
      setLoading(false);
      setIsPhotoChanged(false);
  };

  const handleSettingsChange = (e) => {
    const { name, value } = e.target;
    setSettings((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleContactChange = (e) => {
    const { name, value } = e.target;
    setContact((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleContactSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axios({
      method: "POST",
      url: `${Config.API_URL}/settings/contact`,
      data: contact,
      JSON: true,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        notifySuccess(response.data.message);
        setLoading(false);
      })
      .catch((error) => {
        notifyError(error.response.data.detail);
        setLoading(false);
      });
  };

  const handleInfoSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axios({
      method: "POST",
      url: `${Config.API_URL}/settings/user`,
      data: settings,
      JSON: true,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        notifySuccess(response.data.message);
        setLoading(false);
      })
      .catch((error) => {
        notifyError(error.response.data.detail);
        setLoading(false);
      });
  };
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${Config.API_URL}/profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.profile != null) {
          // Base64 image (you should always start with "data:image/png;base64,")
          setPhoto("data:image/png;base64," + response.data.profile);
        } else {
          setPhoto(defaultProfilePicture)
        }
        setUser(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate("/login");
        } else {
          notifyError(error.response.data.detail);
        }
      }
    };

    fetchUser();
  });

  return (
    <div className="profile">
      <Navbar />
      <div className="container-fluid">
        <Toaster position="top-center" reverseOrder={false} />
        <h1>Hello Dr. {user.first_name} {user.last_name}</h1>

        <div className="row">
          <div className="col-sm-4">
            <div className="profilePhoto">
              <img className="img-fluid" src={photo} alt="profile" />
              <input
                style={{ display: "none" }}
                type="file"
                onChange={fileSelectorHandler}
                ref={fileInput}
              />
              {isPhotoChanged ? (
                <button className="changeButton" onClick={handleFileUpload}>
                {loading ? <>Saving...</> : <>Save Photo </>}
                </button>
              ) : (
                <button
                  className="changeButton"
                  onClick={() => fileInput.current.click()}
                >
                  Change Photo
                </button>
              )}
            </div>
          </div>
          <div className="col-sm-8">
            <div className="profileMain">
              <div className=" tableSearch_Billing">User Setting</div>

              <div className="RegisterName">
                <div className="profileFrom">
                  <label>First Name</label>
                  <input
                    className="RegisterFromData"
                    type="text"
                    name="first_name"
                    placeholder={user.first_name}
                    value={settings.first_name}
                    onChange={handleSettingsChange}
                  />
                </div>
                <div className="profileFrom">
                  <label>Last Name</label>
                  <input
                    className="RegisterFromData"
                    type="text"
                    name="last_name"
                    placeholder={user.last_name}
                    value={settings.last_name}
                    onChange={handleSettingsChange}
                  />
                </div>
              </div>
              <div className="RegisterName">
                <div className="profileFrom">
                  <label>Email Address</label>
                  <input
                    name="email"
                    value={settings.email}
                    onChange={handleSettingsChange}
                    className="RegisterFromData"
                    type="text"
                    placeholder={user.email}
                  />
                </div>
              </div>
              <button className="changeButton" onClick={handleInfoSubmit}>
                {loading ? <>Saving...</> : <>Save Settings </>}
              </button>
            </div>
            <div className=" profileMain">
              <div className=" tableSearch_Billing">Contact Setting</div>
              <div className="profileFrom">
                <label>Practice Name</label>
                <input
                  name="practice_name"
                  value={contact.practice_name}
                  onChange={handleContactChange}
                  className="RegisterFromData"
                  type="text"
                  placeholder={user.practice_name}
                />
              </div>
              <div className="RegisterName">
                <div className="profileFrom">
                  <label>Report Receiving Email</label>
                  <input
                    name="report_email"
                    value={contact.report_email}
                    onChange={handleContactChange}
                    className="RegisterFromData"
                    type="text"
                    placeholder={user.report_email}
                  />
                </div>
                <div className="profileFrom">
                  <label>Address</label>
                  <input
                    name="address"
                    value={contact.address}
                    onChange={handleContactChange}
                    className="RegisterFromData"
                    type="text"
                    placeholder={user.address}
                  />
                </div>
              </div>
              <button className="changeButton" onClick={handleContactSubmit}>
                {loading ? <>Saving...</> : <>Save Settings </>}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Profile;
